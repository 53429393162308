.newsletter {
  width: 100%;
  margin: 0 0 43px;

  @media screen and (min-width: 1024px) {
    width: 294px;
  }

  &.v_minimal {
    // width: auto;
    width: 100%;
    margin: 0;
    position: absolute;
    z-index: 4;
    // right: 13px;
    right: 0;
    bottom: 22px;
    bottom: 0;

    @media screen and (min-width: 1024px) {
      width: 294px;
      right: 84px;
      border-top: none;
      bottom: 38px;
    }

    .newsletter__border {
      display: block;
      width: 100%;
      height: 0;
      border-top: 5px solid #92711D;

      @media screen and (min-width: 1024px) {
        display: none;
      }
    }

    .newsletter__button {
      display: block;
      width: auto;
      position: absolute;
      z-index: 4;
      right: 13px;
      bottom: 22px;

      @media screen and (min-width: 1024px) {
        display: none;
      }
    }

    .newsletter__container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 164px;
      padding: 15px 23px 25px;
      border-top: 5px solid #92711D;
      box-sizing: border-box;
      background-color: white;

      @media screen and (min-width: 1024px) {
        height: auto;
        padding: 0;
        border: none;
        background-color: transparent;
      }
    }

    .newsletter__close_btn {
      display: block;
      width: 16px;
      height: 17px;
      padding: 0;
      border: none;
      cursor: pointer;
      position: absolute;
      top: 15px;
      right: 23px;
      background-image: url('../../assets/img/down-arrow-icon-gold.svg');
      background-size: contain;
      background-position: center;
      background-color: transparent;

      @media screen and (min-width: 1024px) {
        display: none;
      }
    }

    .newsletter__heading {
      // display: none;
      color: #2D3434;

      @media screen and (min-width: 1024px) {
        display: block;
        color: #FFFFFF;
      }
    }

    .newsletter__form {
      // display: none;

      @media screen and (min-width: 1024px) {
        display: flex;
      }

      &__input {
        color: #2D3434;
        border-bottom-color: #2D3434;

        @media screen and (min-width: 1024px) {
          color: #FFFFFF;
          border-bottom-color: #FFFFFF;
        }
      }

      &__button {
        color: #2D3434;
        border-color: #2D3434;

        @media screen and (min-width: 1024px) {
          color: #FFFFFF;
          border-color: #FFFFFF;
        }

        &:hover {
          color: black;
          border-color: white;
          background-color: white;
        }
      }
    }
  }

  &__button {
    display: none;
  }

  &__close_btn {
    display: none;
  }

  &__heading {
    font-family: 'untitled-sans-regular';
    font-size: 8px;
    line-height: 10px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.64px;
    color: #2D3434;
    margin: 0 0 4px;
  }

  &__form {
    display: flex;
    justify-content: space-between;

    &__input {
      flex: 1;
      font-family: 'untitled-sans-regular';
      font-size: 10px;
      line-height: 32px;
      letter-spacing: 0.8px;
      color: #2D3434;
      margin: 0 10px 0 0;
      padding: 0;
      border: none;
      border-bottom: 1px solid #2D3434;
      background-color: transparent;
      -webkit-appearance: none;
      border-radius: 0;

      &:focus {
        outline: 0;
      }
    }

    &__button {
      font-family: 'untitled-sans-regular';
      font-size: 10px;
      line-height: 32px;
      text-transform: uppercase;
      letter-spacing: 0.8px;
      color: #2D3434;
      padding: 0 24px;
      border: 0.5px solid #2E3434;
      border-radius: 16px;
      background-color: transparent;
      cursor: pointer;

      &:hover {
        color: white;
        border-color: #2E3434;
        background-color: #2D3434;
      }

      &:active {
        color: white;
        border-color: black;
        background-color: black;
      }
    }
  }

  &__button {
    font-family: 'untitled-sans-regular';
    font-size: 8px;
    line-height: 10px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.64px;
    color: #FFFFFF;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      color: white;
      border-color: #2E3434;
      background-color: #2D3434;
    }

    &:active {
      color: white;
      border-color: black;
      background-color: black;
    }
  }
}
