.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 21px 0 26px;
  position: relative;
  background-color: #fff;

  &__logo {
    width: 148px;
    height: 23px;

    a {
      text-decoration: none;
      display: block;
    }
  }

  &__menu_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 20px;
    position: absolute;
    top: 0;
    right: 0;

    // &:after {
    //   content: '';
    //   display: block;
    //   width: 25px;
    //   height: 25px;
    //   background-image: url('../../assets/img/menu-icon.svg');
    //   background-size: contain;
    //   background-position: center;
    //   background-repeat: no-repeat;
    // }

    &__icon {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 18px;
      height: 9px;
      padding: 0;
      border: none;
      background-color: transparent;

      &:hover {

        span {
          background-color: #666666
        }
      }

      span {
        width: 100%;
        height: 1px;
        display: block;
        background-color: #2D3434;
      }
    }
  }

  &__nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 900;
    background-color: #2D3434;

    &__logo {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 20px 0;
    }

    &__close_menu_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 21px 20px 26px;
      position: absolute;
      top: 0;
      right: 0;

      &__icon {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        position: relative;
        width: 14px;
        height: 14px;
        padding: 0;
        border: none;
        background-color: transparent;

        &:hover {

          span {
            background-color: white;
          }
        }

        span {
          position: absolute;
          top: 50%;
          left: 0;
          width: 25px;
          height: 1px;
          display: block;
          background-color: #f7f5f5;
          transform: translateY(-50%);
          transition: background-color 400ms ease-out;

          &:first-child {
            left: 1px;
            transform: rotate(-45deg) translateY(-50%);
          }

          &:last-child {
            transform: rotate(45deg) translateY(-50%);
          }
        }
      }
    }

    &__menu {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1 1;
      width: 100%;

      &__list {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0;
        padding: 0 23px;
        box-sizing: border-box;
        list-style-type: none;

        @media screen and (min-width: 1024px) {
          padding: 0 77px;
        }

        &__item {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0;
          padding: 22px 0;
          border-bottom: 1px solid #FFFFFF;

          &:last-child {
            border-bottom: none;
          }

          a {
            font-family: 'domaine-display-condensed-medium';
            font-size: 48px;
            line-height: 47px;
            font-weight: 400;
            text-align: center;
            text-transform: uppercase;
            color: #BCBCBC;
            text-decoration: none;
            transition: color 400ms ease-out;

            &:hover {
              color: #D1AB4B;
            }
          }
        }
      }
    }

    &__copyright {
      width: 100%;
      padding: 14px 0;
      font-family: 'untitled-sans-regular';
      font-size: 12px;
      line-height: 15px;
      color: #FFFFFF;
      text-align: center;
    }
  }
}
