.projects_carousel {
  flex: 1;
  display: flex;
  overflow: hidden;
  position: relative;

  @media screen and (min-width: 1024px) {

  }

  &__list {
    // flex: 1;
    // display: flex;
    position: relative;
    margin: 0;
    padding: 0;
    list-style-type: none;

    &__item {
      flex: 1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      margin: 0;
      padding: 0;

      &:after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.1);
      }

      &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        display: block;
      }

      &__title {
        display: none;

        @media screen and (min-width: 1024px) {
          display: block;
          margin: 25px 0 0 77px;
          font-family: 'domaine-display-condensed-medium';
          font-size: 21px;
          line-height: 28px;
          font-weight: 400;
          text-transform: uppercase;
          color: #D1AB4B;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
        }
      }
    }
  }
}
