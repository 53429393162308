.home_menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 30px 13px 22px 23px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  box-sizing: border-box;
  background-color: transparent;

  @media screen and (min-width: 1024px) {
    padding: 30px 40px 37px;
  }

  &__list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    &.v_work {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    &.v_general {

    }

    &__item {
      margin: 0;
      padding: 0;

       > a {
        display: flex;
        align-items: center;
        font-family: 'domaine-display-condensed-medium';
        font-size: 48px;
        line-height: 47px;
        font-weight: 400;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
        text-decoration: none;
      }

      &__arrow_icon {
        display: block;
        position: absolute;
        right: -37px;
        width: 17px;
        height: 16px;
        margin-left: 8px;
        background-image: url('../../assets/img/right-arrow-icon-white.svg');
        background-size: contain;
        background-position: center;

        @media screen and (min-width: 1024px) {
        }
      }

      &.v_sub_menu {
        padding-right: 37px;

        > a {
          position: relative;
        }

        ul {
          list-style-type: none;
          margin: 0;
          padding: 0;

          li {
            margin: 0;
            padding: 0;
            text-align: right;

            a {
              font-family: 'domaine-display-condensed-medium';
              font-size: 28px;
              line-height: 47px;
              font-weight: 400;
              text-align: center;
              color: #FFFFFF;
              text-decoration: none;

              &.v_index_link {
                color: #92711D;
              }
            }
          }
        }
      }
    }
  }
}
