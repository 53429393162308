@import './assets/fonts/untitled-sans-regular/font';
@import './assets/fonts/domaine-display-condensed-medium/font';

html {
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Ccircle id='Ellipse_6' data-name='Ellipse 6' cx='6' cy='6' r='6' fill='%23d5d6d6'/%3E%3C/svg%3E%0A") 6 6, auto;

  cursor: -webkit-image-set(url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAABHNCSVQICAgIfAhkiAAAAIhJREFUKFNjZEAC165dK/3HwBDOyMCgCxL+z8BwmYmBYaWWllY3TBlQDih6+bIyIzPzSiDHGNkAGBuo8ez/v3/DdXV174I1XLl27QwuxciadLS0TBhBzgCa0IXNZHQxoKFljMSYjmwL49Vr134CBdiIsQGo5hfpGkh2EsmeJjlYyYo4WAgRkzQAIudNh6NcoSMAAAAASUVORK5CYII=") 1x,
            url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAATJJREFUSEu9VkFOwzAQ9IQ/IPGBViZ9BBd+wImeQeoX4IA4wBeQ4AynvoBe+ojEFn0ASLyBZJlEcUlUEtkQ1xdLyezO7o53baiBZa2dlGV5BuBElJpxP6zgIvIJpTLu6yRJllrrTZ8b4nZX4/hGAedDAWz/ibyQ6PY3oh2CzNoLiDzQ+MDL+Q+oEGAx0/qpbdchyPP8ilHfBTruwkWu0zS9dx+3BE3kj/9y3hgzk0uXSU1Q11zE/KEsffEUCXBcaVITsDTP3oL6pkjhWao5mujffO1CcMxiilGE7WOl4DDGvLKJTkMi88Wy/ivkxrzT4MjXKBD3AWPtF1s+tKm8eDhaivgE8UsUXeQx5s/QMY3eaNFHxV6GXUUSdVw7jUaZS30XjiOJemU6kqiXfvtIj/Fs+QboeMWnmyfDNQAAAABJRU5ErkJggg==") 2x) 6 6, pointer;

  // cursor: url('./assets/img/mouse.png'), auto;
}

a,
button {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Ccircle id='Ellipse_6' data-name='Ellipse 6' cx='6' cy='6' r='6' fill='%23d1ab4b'/%3E%3C/svg%3E%0A") 6 6, auto;

  cursor: -webkit-image-set(url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAABHNCSVQICAgIfAhkiAAAAIRJREFUKFNjZEACF1d5lTIwMoYDhXShwpcZ/v9fqR+2rRumjBHEOLPCU5mVmWklkGmMbAAS++zvv//CTSK23wVruLja+wwexTB9Z/VDt5owQp3RhcNkVOH//8sYiTQdbgtIw08gj40oGxgYfpGlgRgPIzkJEvbEe5rkYCUr4mAOJCZpAABEHkUn+juqyAAAAABJRU5ErkJggg==") 1x,
            url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAATRJREFUSEtjZMADLqzzUmP8yxjCyMDgAFSm85+BQQykHMh/BaSuAPkH/jP/X2MQtO0WLmOAajEB2OA/DPUMjIxR+BwAl/v/f9l/FoZGbBZhWHBpjU/qv///pwMlmIkyHKoI6Ju/TIyMmXohW2Yj60Ox4OJq7yqgZCspBmNRW60furUNJg63AOTy////z6LQcLB2RkbGNJhPwBaAwpzhL+M1UoMFl2NAwcXA/F8LFCdgCy6u8lpKdIQS60VgxOuHbYtmhCbFm8TqI0UdMAmrM1IpYnHZW814abX3LmCYuZLiMmLVAsN/N8iCZ0ALJInVRIo6oAXPQUH0B6iJpExFgiV/aW8BzYOI5pFM82RK84xG86KCLoUdyBKaFtewjEOlCMde4cAsoWmVCbOEppU+cjlDjWYLALfOqF1uSC65AAAAAElFTkSuQmCC") 2x) 6 6, pointer;

  // cursor: url('./assets/img/mouse-hover.png'), auto;
}

.page {
  padding: 0 23px 52px;
  background-color: #f1f1f1;

  @media screen and (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    padding: 0 0 60px;
  }

  &__heading {
    font-family: 'domaine-display-condensed-medium';
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    color: #2D3434;
    text-align: center;
    margin: 0;
    padding: 13px 32px 16px;

    @media screen and (min-width: 1024px) {
      font-size: 21px;
      line-height: 28px;
      padding: 22px 0 24px;
    }
  }
}

.home {
  display: flex;
  flex: 1;
  // min-height: 100vh;
  position: relative;
  overflow: hidden;

  @media screen and (min-width: 1024px) {
    border-bottom: none;
  }
}

.projects {
  min-height: 100vh;
  background-color: #F4F0E8;
}

.project_view {
  display: flex;
  flex-direction: column;
  background-color: #F4F0E8;
}

.studio {

  @media screen and (min-width: 1024px) {
  }

  &__image {
    display: block;
    width: 100%;

    @media screen and (min-width: 1024px) {
      width: auto;
      max-width: 45vw;
    }
  }

  &__content {
    margin: 28px 0 40px;

    @media screen and (min-width: 1024px) {
      display: flex;
      // width: 45vw;
      // max-width: 58vw;
      width: 90vw;
      max-width: 90vw;
      margin: 50px 0;
    }

    &__description {
      font-family: 'untitled-sans-regular';
      font-size: 16px;
      line-height: 21px;
      font-weight: 400;
      color: #707070;
      padding: 0 0 28px;

      @media screen and (min-width: 1024px) {
        flex: 1;
        padding: 0 30px 0 0;
      }

      h2,
      p {
        font-family: 'untitled-sans-regular';
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        color: #707070;
        margin: 0;
      }

      h2 {
        text-decoration: underline;
      }
    }

    &__what_we_do {
      flex: 1;
      display: flex;
      flex-direction: column;

      @media screen and (min-width: 1024px) {
        padding: 0 0 0 44px;
        border-left: 1px solid #707070;
      }

      h2,
      a {
        font-family: 'untitled-sans-regular';
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        color: #707070;
        margin: 0;
      }

      h2 {
        text-decoration: underline;
      }

      a {
        text-decoration: none;
        display: block;
        margin-bottom: 1em;
      }

      &__heading {
        font-family: 'untitled-sans-regular';
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        color: #707070;
        width: 100%;
        margin: 0 0 10px;
        padding: 0 0 10px;
        border-bottom: 1px solid #707070;

        @media screen and (min-width: 1024px) {
          padding: 0;
          border-bottom: none;
        }
      }

      &__services {
        // flex: 1;
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        margin: 0;
        padding: 0;

        &__item {
          flex: 0 1 50%;
          font-family: 'untitled-sans-regular';
          font-size: 16px;
          line-height: 21px;
          font-weight: 400;
          color: #707070;
          margin: 0;
          padding: 0;
        }
      }
    }

    &__team {
      
      @media screen and (min-width: 1024px) {
        display: flex;
        gap: 40px;
      }

      &__intro {
        margin-bottom: 20px;

        @media screen and (min-width: 1024px) {
          flex-basis: 30%;
          max-width: 400px;
          margin-bottom: 0;
          padding-top: 100px;
        }

        &__heading {
          font-family: 'domaine-display-condensed-medium';
          font-size: 24px;
          line-height: 32px;
          font-weight: 400;
          text-transform: uppercase;
          color: #92711D;
          margin: 0 0 24px;
  
          @media screen and (min-width: 1024px) {
            font-size: 48px;
            line-height: 52px;
            margin-bottom: 28px;
            display: block;
          }
        }

        &__description {
          font-family: 'untitled-sans-regular';
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
          color: #707070;
          margin: 0;
  
          @media screen and (min-width: 1024px) {
            // width: 50%;
            padding: 0;
          }
        }
      }

      &__members {
        list-style-type: none;
        margin: 0;
        padding: 0;

        @media screen and (min-width: 1024px) {
          flex: 1;
          display: grid;
          gap: 20px;
          grid-template-columns: repeat(5, 1fr);
        }

        &__divider {
          height: 1px;
          margin: 20px 0;
          background-color: #92711D;
        }

        &__item {
          margin: 0 0 20px;
          padding: 0;

          @media screen and (min-width: 1024px) {
            position: relative;
            margin: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }

          &__image {
            display: block;
            width: 100%;
            height: auto;
            margin-bottom: 10px;
            object-fit: cover;
            object-position: center;
            aspect-ratio: 1 / 1;

            @media screen and (min-width: 1024px) {
              margin-bottom: 0;
            }
          }

          &__info {

            @media screen and (min-width: 1024px) {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              padding: 10px;
              background-color: rgba(0, 0, 0, 0.5);
              opacity: 0;
              transition: opacity 300ms ease-in-out;
            }

            &:hover {
                
              @media screen and (min-width: 1024px) {
                opacity: 1;
              }
            }

            &__name {
              font-family: 'domaine-display-condensed-medium';
              font-size: 24px;
              line-height: 32px;
              font-weight: 400;
              text-transform: uppercase;
              color: #2D3434;
              margin: 0 0 5px;
    
              @media screen and (min-width: 1024px) {
                font-size: 24px;
                line-height: 31px;
                color: #fff;
                margin-bottom: 2px;
                display: block;
              }
            }

            &__position {
              font-family: 'untitled-sans-regular';
              font-size: 12px;
              line-height: 15px;
              font-weight: 400;
              text-transform: uppercase;
              letter-spacing: 0.96px;
              color: #2D3434;
              margin: 0 0 5px;

              @media screen and (min-width: 1024px) {
                margin: 0;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }

  &__booking_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'untitled-sans-regular';
    font-size: 10px;
    line-height: 12px;
    font-weight: 400;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    text-decoration: none;
    color: #2D3434;
    height: 32px;
    padding: 10px 18px;
    border: 1px solid #2E3434;
    border-radius: 16px;
    background-color: white;
    box-sizing: border-box;

    &:hover {
      color: white;
      border-color: #2E3434;
      background-color: #2D3434;
    }

    &:active {
      color: white;
      border-color: black;
      background-color: black;
    }
  }
}

.press_categories {
  padding: 0 65px 70px;

  @media screen and (min-width: 1024px) {
    padding: 0 0 84px;
  }

  &__categories {
    list-style-type: none;
    margin: 0;
    padding: 0;
    border-top: 1px solid white;
    border-left: 1px solid white;
    background-color: f1f1f1;

    @media screen and (min-width: 1024px) {
      display: grid;
      gap: 0px;
      grid-template-columns: repeat(3, 1fr);
      max-width: 70%;
    }

    &__category {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 0 1px;
      padding: 0;
      border-right: 1px solid white;
      border-bottom: 1px solid white;
      position: relative;
      background-color: #f1f1f1;
      aspect-ratio: 1;

      @media screen and (min-width: 1024px) {
        margin: 0;
      }

      &:hover {

        .press_categories__categories__category__image {
          opacity: 1;
        }
      }

      &__block_link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
      }

      &__image {
        display: block;
        width: 60%;
        height: auto;
        opacity: 0.7;
        transition: opacity 400ms ease-out;
      }
    }
  }
}

.press {
  padding: 0 65px 70px;

  @media screen and (min-width: 1024px) {
    padding: 0 0 84px;
  }

  &__stories {
    list-style-type: none;
    margin: 0;
    padding: 0;

    @media screen and (min-width: 1024px) {
      display: grid;
      gap: 40px;
      grid-template-columns: repeat(3, 1fr);
      max-width: 70%;
    }

    &__story {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 0 26px;
      padding: 0;
      position: relative;

      @media screen and (min-width: 1024px) {
        margin: 0;
      }

      &:hover {

        .press__stories__story__img__hover {
          opacity: 1;
        }

        .press__stories__story__title,
        .press__stories__story__date {
          color: #2D3434;
        }
      }

      &__block_link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
      }

      &__img {
        position: relative;
        margin: 0 0 10px;
        padding: 20px;
        box-sizing: border-box;
        background-color: white;

        &__image {
          display: block;
          width: 100%;
          height: auto;
        }

        &__hover {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(45, 52, 52, 0.36);
          opacity: 0;
          transition: opacity 300ms ease-out;

          &:after {
            content: '';
            display: block;
            width: 17px;
            height: 16px;
            background-image: url('./assets/img/right-arrow-icon-white.svg');
            background-size: contain;
            background-position: center;
          }
        }
      }

      &__title {
        font-family: 'untitled-sans-regular';
        font-size: 12px;
        line-height: 15px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 0.96px;
        color: #999;
        margin: 0;
      }

      &__date {
        font-family: 'untitled-sans-regular';
        font-size: 12px;
        line-height: 15px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 0.96px;
        color: #999;
      }
    }
  }
}

.contact {

  @media screen and (min-width: 1024px) {
  }

  &__info {
    padding: 0 0 8px;

    @media screen and (min-width: 1024px) {
      display: flex;
      width: 100%;
      max-width: 63vw;
      padding: 0;
    }

    &__details {
      flex: 1;

      h2 {
        font-family: 'untitled-sans-regular';
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        color: #707070;
        text-decoration: underline;
        margin: 0;
        padding: 0 30px;

        @media screen and (min-width: 1024px) {
          padding: 0;
        }
      }

      p {
        font-family: 'untitled-sans-regular';
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        color: #707070;
        margin: 0 0 30px;
        padding: 0 30px;

        @media screen and (min-width: 1024px) {
          padding: 0;
        }
      }

      a {
        display: block;
        font-family: 'untitled-sans-regular';
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        color: #707070;
        text-decoration: none;
        margin: 0 0 30px;
        padding: 0 30px;

        @media screen and (min-width: 1024px) {
          padding: 0;
        }
      }

      img {
        display: block;
        width: 100%;
        margin: 0 0 30px;

        @media screen and (min-width: 1024px) {
          display: none;
        }
      }

      &__map {
        width: 100vw;
        height: 100vw;
        margin: 0 0 30px;
        padding: 30px;
        box-sizing: border-box;
      }
    }

    &__map {
      display: none;
      padding: 15px;
      background-color: white;

      @media screen and (min-width: 1024px) {
        display: block;
        flex: 1;
      }

      &__image {
        display: block;
        width: 100%;
        padding: 15px;
        box-sizing: border-box;
        background-color: white;
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;

    &:before {

      @media screen and (min-width: 1024px) {
        content: '';
        display: block;
        width: 1px;
        height: 143px;
        margin: 43px 0;
        background-color: #707070;
      }
    }

    &__visit_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'untitled-sans-regular';
      font-size: 10px;
      line-height: 12px;
      font-weight: 400;
      letter-spacing: 0.8px;
      text-transform: uppercase;
      text-decoration: none;
      color: #2D3434;
      height: 32px;
      padding: 10px 18px;
      border: 1px solid #2E3434;
      border-radius: 16px;
      background-color: white;
      box-sizing: border-box;

      &:hover {
        color: white;
        border-color: #2E3434;
        background-color: #2D3434;
      }

      &:active {
        color: white;
        border-color: black;
        background-color: black;
      }
    }
  }
}
