.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 35px 12px;
  border-bottom: 10px solid #F4F0E8;
  background-color: white;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 76px 77px 12px;
  }

  &__logo {
    margin: 0 0 29px;

    @media screen and (min-width: 1024px) {
      width: 100%;
    }

    a {
      display: block;

      img {
        display: block;
      }
    }
  }

  &__nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin: 0 0 48px;

    @media screen and (min-width: 1024px) {
      flex-direction: row;
      width: auto;
    }

    &__list {
      margin: 0;
      padding: 0;
      list-style-type: none;

      @media screen and (min-width: 1024px) {
        margin: 0 36px 0 0;
      }

      &__item {
        margin: 0;
        padding: 0;
        font-family: 'untitled-sans-regular';
        font-size: 12px;
        line-height: 21px;
        color: #D5D6D6;
        text-transform: uppercase;
        letter-spacing: 0.96px;

        a {
          text-decoration: none;
          color: #D5D6D6;

          &:hover {
            color: #2D3434;
          }
        }
      }
    }
  }

  &__copyright {
    font-family: 'untitled-sans-regular';
    font-size: 10px;
    line-height: 12px;
    color: #D5D6D6;

    @media screen and (min-width: 1024px) {
      width: 100%;
    }
  }
}
