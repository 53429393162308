.projects_index {
  background-color: #F4F0E8;

  &__title {
    font-family: 'domaine-display-condensed-medium';
    font-weight: 400;
    font-size: 21px;
    line-height: 28px;
    text-transform: uppercase;
    text-align: center;
    color: #92711D;
    margin: 0;
  }
  
  &__listing {
    list-style-type: none;
    margin: 0;
    padding: 25px 23px 30px;

    @media screen and (min-width: 1024px) {
      display: grid;
      gap: 48px;
      grid-template-columns: repeat(3, 1fr);
      padding: 33px 77px 48px;
    }

    &__item {
      margin: 0 0 30px;
      padding: 0;
      position: relative;

      @media screen and (min-width: 1024px) {
        margin-bottom: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &__image {
        width: 100%;
        height: 260px;
        object-fit: cover;
        object-position: center;
        display: block;
      }

      &__title {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        margin: 0;
        position: absolute;
        left: 0;
        top: 0;
        font-family: 'domaine-display-condensed-medium';
        font-weight: 400;
        font-size: 28px;
        line-height: 37px;
        color: white;
        background-color: rgba(45, 52, 52, 0.36);

        @media screen and (min-width: 1024px) {
          opacity: 0;
          transition: opacity 300ms ease-out;
        }

        &:hover {

          @media screen and (min-width: 1024px) {
            opacity: 1;
          }

          &:after {
            transform: translateX(10px);
            transition: transform 200ms ease-out;
            transition-delay: 300ms;
          }
        }

        &:after {
          content: '';
          display: block;
          width: 17px;
          height: 16px;
          margin-left: 15px;
          background-image: url('../../assets/img/right-arrow-icon-white.svg');
          background-size: contain;
          background-position: center;
        }
      }
    }
  }
}
