.product {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: calc(100vh - 70px);
  background-color: #fff;

  @media screen and (min-width: 1024px) {

  }

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'domaine-display-condensed-medium';
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
    text-transform: uppercase;
    color: #92711D;
    margin: 0;
    padding: 0 22px;
    background-color: #fbfaf8;

    @media screen and (min-width: 1024px) {
      display: none;
    }

    &__position {
      font-family: 'untitled-sans-regular';
      font-size: 12px;
      line-height: 15px;
      font-weight: 400;
      letter-spacing: 0.96px;
      color: #92711D;
    }
  }

  &__content {
    display: flex;
    flex: 1;
    width: 100vw;
    overflow-x: auto;
    background-color: #F4F0E8;

    &__info {
      display: flex;
      flex: 0 0 87vw;
      flex-direction: column;
      height: 100%;
      padding: 26px 83px 65px 19px;
      box-sizing: border-box;
      overflow-y: auto;

      @media screen and (min-width: 1024px) {
        flex: 0 0 466px;
        padding: 170px 42px 80px 78px;
      }

      &__heading {
        font-family: 'domaine-display-condensed-medium';
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
        text-transform: uppercase;
        color: #92711D;
        margin: 0 0 24px;
        display: none;

        @media screen and (min-width: 1024px) {
          font-size: 48px;
          line-height: 62px;
          margin-bottom: 28px;
          display: block;
        }
      }

      &__introduction {
        font-family: 'untitled-sans-regular';
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        color: #707070;
        margin: 0;

        @media screen and (min-width: 1024px) {
          padding: 0;
        }
      }

      &__meta {
        margin: 1em 0;

        &__heading {
          font-family: 'untitled-sans-regular';
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
          color: #707070;
          margin: 0;
        }

        &__list {
          margin: 0;
          padding: 0;
          list-style-type: none;

          &__item {
            font-family: 'untitled-sans-regular';
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
            font-style: italic;
            color: #707070;
            margin: 0;
            padding: 0;
          }
        }
      }

      &__instruction {
        display: flex;
        align-items: center;
        font-family: 'domaine-display-condensed-medium';
        font-size: 21px;
        line-height: 28px;
        font-weight: 400;
        text-align: center;
        color: #92711D;
        margin: 56px 0 0;

        @media screen and (min-width: 1024px) {
          // display: none;
        }

        &:after {
          content: '';
          display: block;
          width: 17px;
          height: 16px;
          margin: 0 0 0 15px;
          background-image: url('../../assets/img/right-arrow-icon-gold.svg');
          object-fit: contain;
          object-position: center;
        }
      }

      &__shop_link {
        flex: 0 1 0;
        justify-content: center;
        align-items: center;
        font-family: 'untitled-sans-regular';
        font-size: 10px;
        line-height: 12px;
        font-weight: 400;
        letter-spacing: 0.8px;
        text-transform: uppercase;
        text-decoration: none;
        color: #2D3434;
        height: 32px;
        padding: 10px 18px;
        border: 1px solid #2E3434;
        border-radius: 16px;
        background-color: white;
        box-sizing: border-box;

        &:hover {
          color: white;
          border-color: #2E3434;
          background-color: #2D3434;
        }

        &:active {
          color: white;
          border-color: black;
          background-color: black;
        }
      }
    }

    &__nav {
      display: flex;
      flex: 0 0 78vw;
      flex-direction: column;
      justify-content: center;

      @media screen and (min-width: 1024px) {
        flex: 0 0 50vw;
        align-items: center;
      }

      &__next_btn {
        display: flex;
        align-items: center;
        font-family: 'domaine-display-condensed-medium';
        font-size: 21px;
        line-height: 28px;
        font-weight: 400;
        text-align: center;
        text-decoration: none;
        color: #92711D;
        margin: 0 0 0 27px;

        &:before {
          content: '';
          display: block;
          width: 16px;
          height: 17px;
          margin: 0 15px 0 0;
          background-image: url('../../assets/img/right-arrow-icon-gold.svg');
          object-fit: contain;
          object-position: center;
        }
      }

      &__divider {
        display: block;
        width: 100%;
        height: 1px;
        background-color: #8D722F;
        margin: 31px 0;
      }

      &__back_btn {
        display: flex;
        align-items: center;
        font-family: 'domaine-display-condensed-medium';
        font-size: 21px;
        line-height: 28px;
        font-weight: 400;
        text-align: center;
        text-decoration: none;
        color: #92711D;
        margin: 0 0 0 27px;

        &:before {
          content: '';
          display: block;
          width: 16px;
          height: 17px;
          margin: 0 15px 0 0;
          background-image: url('../../assets/img/down-arrow-icon-gold.svg');
          object-fit: contain;
          object-position: center;
        }
      }
    }
  }
}
