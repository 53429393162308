.gallery {
  // flex: 1;
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  // flex-wrap: nowrap;
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: relative;
  cursor: url('../../assets/img/next.png'), auto;
  transition: opacity 100ms ease-out;
  overflow-x: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */

  &.prev_cursor {
    cursor: url('../../assets/img/prev.png'), auto;
  }

  &::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
  }

  &__item {
    // flex: 1;
    display: inline;
    position: relative;
    margin: 0 24px 0 0;
    padding: 0 0 24px;
    // border-left: 1px solid black;
    // background-color: grey;

    @media screen and (min-width: 1024px) {
      margin-right: 24px;
    }

    &:last-of-type {
      margin-right: 0;

      @media screen and (min-width: 1024px) {
        margin-right: 0;
      }
    }

    &__image {
      display: block;
      width: auto;
      height: 100%;
      // object-fit: cover;
      // object-position: center;
      // display: none;
    }

    &__pinterest_link {
      position: absolute;
      bottom: 24px;
      right: 0;
      z-index: 890;
      display: block;
      width: 30px;
      height: 30px;
      padding: 20px;
      cursor: pointer;
      filter: grayscale(1);
    }
  }

  &__position {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 24px;
    padding: 0 0 0 77px;
    box-sizing: border-box;

    &__title {
      font-family: 'untitled-sans-regular';
      font-size: 8px;
      line-height: 24px;
      font-weight: 400;
      letter-spacing: 0.64px;
      color: #92711D;
      text-transform: uppercase;
    }

    &__count {
      font-family: 'untitled-sans-regular';
      font-size: 8px;
      line-height: 24px;
      font-weight: 400;
      letter-spacing: 0.64px;
      color: #92711D;
      margin-left: 52px;
    }
  }
}
