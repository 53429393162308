.projects_intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-bottom: 25px;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    align-items: flex-start;
    padding-bottom: 33px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0 0;
    box-sizing: border-box;

    @media screen and (min-width: 1024px) {
      align-items: flex-start;
      width: 50vw;
      padding: 140px 225px 140px 78px;
    }

    &__heading {
      font-family: 'domaine-display-condensed-medium';
      font-size: 24px;
      line-height: 32px;
      font-weight: 400;
      text-align: center;
      text-transform: uppercase;
      color: #92711D;
      margin: 0 0 24px;

      @media screen and (min-width: 1024px) {
        font-size: 48px;
        line-height: 62px;
        margin-bottom: 28px;
      }
    }

    &__introduction {
      font-family: 'untitled-sans-regular';
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      color: #707070;
      margin: 0;
      padding: 0 22px 29px;

      @media screen and (min-width: 1024px) {
        padding: 0;
      }
    }

    &__instruction {
      display: none;


      @media screen and (min-width: 1024px) {
        font-family: 'domaine-display-condensed-medium';
        font-weight: 400;
        color: #92711D;
        display: block;
      }

      &:after {

        @media screen and (min-width: 1024px) {
          content: '';
          display: block;
          width: 16px;
          height: 17px;
          margin-top: 30px;
          background-image: url('../../assets/img/down-arrow-icon-gold.svg');
          background-size: contain;
          background-position: center;
        }
      }
    }

    &__instruction_mobile {
      display: block;
      font-family: 'domaine-display-condensed-medium';
      font-weight: 400;
      color: #92711D;
      padding: 0 22px;

      @media screen and (min-width: 1024px) {
        display: none;
      }

      &:after {
        content: '';
        display: block;
        width: 16px;
        height: 17px;
        margin-top: 30px;
        background-image: url('../../assets/img/down-arrow-icon-gold.svg');
        background-size: contain;
        background-position: center;
      }
    }
  }

  &__image {
    display: block;
    width: 100vw;
    height: 100vw;
    background-color: grey;

    @media screen and (min-width: 1024px) {
      width: 50vw;
      height: 50vw;
    }
  }

  &__instruction {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'domaine-display-condensed-medium';
    font-size: 21px;
    line-height: 28px;
    font-weight: 400;
    text-align: center;
    color: #92711D;
    margin: 18px 0 0;

    @media screen and (min-width: 1024px) {
      display: none;
    }

    &:after {
      content: '';
      display: block;
      width: 16px;
      height: 17px;
      margin: 15px 0 0;
      background-image: url('../../assets/img/down-arrow-icon-gold.svg');
      object-fit: contain;
      object-position: center;
    }
  }
}
