.filter {

  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style-type: none;

    &__item {
      margin: 0 40px;
      padding: 0;
      font-family: 'untitled-sans-regular';
      font-size: 12px;
      line-height: 15px;
      font-weight: 400;
      letter-spacing: 0.96px;
      text-transform: uppercase;
      color: #2D3434;
      cursor: pointer;
      transition: opacity 200ms ease-out;

      &:hover {
        opacity: 0.2;
      }

      &.active {
        color: #92711D;
      }
    }
  }
}
